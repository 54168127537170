import * as React from "react";

import Layout from "../components/layout";

const CheckYourEmail = () => (
  <Layout>
    <h1>Check your email</h1>
    <p>
      We've sent you a password reset email. If you didn't receive it, email us
      at team@react.school to get things sorted out.
    </p>
  </Layout>
);

export default CheckYourEmail;
